<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-btn color="success" @click="$router.push('/opinions/new')">Add opinion</v-btn>
            </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="opinions"
                    :items-per-page="10"
                    disable-sort
                    class="elevation-1"
                >
                    <template v-slot:item.actions="{ item }">
                        <v-icon @click="$router.push(`/opinions/edit/${item.id}`)">edit</v-icon>
                        <v-icon @click="openDeleteModal(item)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <confirm-delete-modal
            :isOpen="!!opinionToDelete"
            @disagree="opinionToDelete = null"
            @agree="removeOpinion"
        />
    </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import ConfirmDeleteModal from '@/components/modals/ConfirmDeleteModal';

export default {
    components: {
        ConfirmDeleteModal
    },

    data: () => ({
        opinions: [],
        total: 0,
        headers: [
            {
                text: 'Id',
                value: 'id',
                align: 'center'
            },
            { text: 'Customer', value: 'customer_name', align: 'center' },
            { text: 'Company', value: 'company', align: 'center' },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center'
            }
        ],
        opinionToDelete: null
    }),

    async mounted() {
        try {
            const opinions = await this.getOpinions();

            this.opinions = opinions.rows;
            this.total = opinions.count;
        } catch (err) {
            console.error(err);

            this.$toasted.error('Something went wrong!');
        }
    },

    methods: {
        ...mapActions({
            getOpinions: 'opinions/getOpinions',
            deleteOpinion: 'opinions/deleteOpinion'
        }),

        openDeleteModal(opinion) {
            this.opinionToDelete = opinion;
        },

        async removeOpinion() {
            try {
                await this.deleteOpinion(this.opinionToDelete.id);

                const opinionIndex = this.opinions.findIndex(opinion => {
                    return opinion.id === this.opinionToDelete.id;
                });

                this.opinions.splice(opinionIndex, 1);

                this.opinionToDelete = null;

                this.$toasted.success('Opinion has been removed!');
            } catch (err) {
                console.error(err);

                this.$toasted.error('Something went wrong!');
            }
        }
    }
};
</script>

<style>
</style>