<template>
    <v-dialog v-model="isOpen" persistent max-width="290">
        <v-card>
            <v-card-title class="headline">Are you sure?</v-card-title>
            <v-card-text>Are you sure?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="$emit('disagree')">Disagree</v-btn>
                <v-btn color="green darken-1" text @click="$emit('agree')">Agree</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false
        }
    }
};
</script>

<style>
</style>